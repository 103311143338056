import React from 'react'
import Layout from '../../components/Layout'
import { SEO } from '../../components/seo'
import ClientReviews from '../../components/ClientReviews'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faYoutube, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faListCheck, faBullhorn, faAd } from '@fortawesome/free-solid-svg-icons'
import socialMediaMarketing from '../../images/social-media-marketing.webp'

const pStyles = {
  margin: "25px 0 40px",
  fontSize: "18px",
  lineHeight: "1.5",
  letterSpacing: ".5px"
}

const rowPadding = {
  padding: "150px 0"
}

const iconPadding = {
  padding: "14px"
}

export default function SocialMediaMarketing() {
  return (
    <Layout>
      <section id='social_media' className='container'>
        <div className='row gx-5 flex-row-reverse' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
                <img src={socialMediaMarketing} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h1 className='display-5 mb-4'>Social Media Marketing</h1>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              Social media has been widely used as a tool to promote a product or a business to a larger audience. This has also been used to stimulate growth, increase website traffic, and attain the high rank in search engine ladder. Using the power of social media and its influence on your target audience has been proven successful for many businesses to get their brand recognized and boost their sales. 
            </p>
            <a className="btn btn-orange default-padding mt-4 shadow text-capitalize" href="/get-started" role="button">Get started</a>
          </div>
        </div>
      </section>   

      <section className='w-100 social-media-design parallax text-dark'>
        <div className='container'>
          <div className='row gx-5 justify-content-center text-center' style={rowPadding}>
            <div className='col-12 col-md-10'>
              <p className='text-primary' style={{ fontFamily: "GraphikSuper", letterSpacing: "1px"}}>BRAND IDENTITY</p>
              <h2 className='display-6 mb-4'>Get Your Products Known to Your Target Customers</h2>
              <div className='line-divider mx-auto my-3'></div>
              <p className='mt-4' style={pStyles}>
                Our diverse team have a variety of experience in working with range of industries of different size and difficulties. Together they will be able to plan and put into action a digital marketing campaign that will acquire your business sales, potential customers and boost your brand. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <ul className='row list-unstyled g-4 my-0 text-center' style={rowPadding}>
          <h2 className='display-6 mt-0 text-capitalize'>Social media marketing services</h2>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faListCheck} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Social media management</h2>
              <p>
                Driven by our desire and passion, we endeavor to help you build your brand, acquire more customers, and increase your sales and revenue. Our knowledgeable and experienced team will help you come out with a strategy, create a plan, and execute that plan into action to achieve a successful marketing campaign. Our Team will make use of the power of internet to your advantage to get your brand recognized. Get it touch with us…we are here to help. 
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faBullhorn} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Social media marketing</h2>
              <p>
                The way we process our social media marketing is accurate, on-point and efficient. We try to concentrate on making your brand recognized by your target audience. Our aim is to turn your website traffic into loyal customers. We create catching, engaging and up to date posts, short stories, or video content on all social media platforms like Facebook, Snapchat, Instagram, YouTube, and the like. Let us chat to discuss further how we can help you achieve your marketing goals.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faAd} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Social media advertising</h2>
              <p>
                The bottom line of social media advertising is faster customer acquisition. As your trustworthy social media marketing representative and with the help of our experienced team, we use proven methods and all forms of marketing tools available to boost marketing campaigns to help you achieve your target goals. Talk to us and let us take care of your concerns to help you achieve your business goals.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faFacebookSquare} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Facebook marketing</h2>
              <p>
                With more than 2 billion users worldwide, Facebook is an important part of the marketing strategy. This leading social media giant became the main platforms for many businesses to promote their products or services, get their brand recognized and drive growth in their business. Our team are very efficient in planning and helping you get a brand recognition that will boost your revenue.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faYoutube} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>YouTube marketing</h2>
              <p>
                With billions of views and millions of active users worldwide, this incredible number showcase how brands can utilize the YouTube platform to market their products and get recognized across all channels. You will be surprised how some video can boost SEO’s, create large traffic in your website, get your brand recognized and reach larger audiences which in turn can be your potential customers. You can also get your ROI improved through using YouTube as your form or marketing.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faInstagram} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Instagram marketing</h2>
              <p>
                Instagram is widely used worldwide from well-known influencers to models, actors, and many businesses. Many businesses use Instagram to directly communicate with their customers, to showcase their brand and services and also to accept bookings. With its popularity, there’s a limitless possibility of using this tool to the full such as running advertising, posting updates, and getting regular engagement with your audience. Our experienced team will help you optimize your brand through Instagram marketing.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faTwitter} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>Twitter marketing</h2>
              <p>
                Like other Social Media platform, twitter advertising also offers great benefits to help your business grow. It can promote your products and services, drive traffic to your website and gain potential clients. If done properly, Twitter marketing can help you boost your brand identity and credibility. Most consumers will use a product or services from a trusted brand. Googolweb can help you create a strong and reliable brand.
              </p>
            </div>
          </li>

          <li className='col-md-4'>
            <div className='bg-white text-dark p-4 rounded' style={{ height: "490px" }}>
              <FontAwesomeIcon icon={faLinkedinIn} className='bg-primary fs-4 text-white rounded-circle mb-3' style={iconPadding} />
              <h2 className='h5 text-capitalize mb-3'>LinkedIn marketing</h2>
              <p>
                Appropriately named LinkedIn, it is all about creating connections. This tool can help you grow your business, connect with your target audience, and improve brand awareness. If utilized right, LinkedIn can help your business create a sustainable brand image. Our team of experts will be able to assist you to make this possible.
              </p>
            </div>
          </li>
          
        </ul>
      </section>

      <section className='w-100 dot-pattern text-dark'>
        <div className='container' style={rowPadding}>
          <h2 className='display-6 text-center mb-5'>GoogolWeb Advantage</h2>
          <div className="inline-scrollbox u-scroll-x home-integrations-scrollbox">

          <ul className='inline-scrollbox-wrapper text-white'>
              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Competitive Pricing</h4>
                <p>Offering the most modern, innovative website at a very competitive rate- is the best offer there is!</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Get It Done By The Experts</h4>
                <p>Our experienced designers and developers guarantee you will be completely satisfied with the end results.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>You Are Always In The Know</h4>
                <p>Our Team will work closely with you every step of the way to ensure you are always informed of the progress of your website development.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Transparency of Operations</h4>
                <p>We value open communication and transparency in all our project for we believe a good alliance is founded on trust and openness.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>SEO Friendly Websites</h4>
                <p>We build websites with an SEO advantage to give you a headstart in SERPs.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Security</h4>
                <p>We build advanced security features into each one of the websites that we undertake to protect our client’s businesses from cyber attacks.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "272px" , padding: "35px 30px"}}>
                {/* <div class="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Timely Delivery</h4>
                <p>Before we go ahead with your website development, we ensure that we give you a clear and realistic timeline to complete your website.</p>
              </li>
             
            </ul>

          </div>
        </div>
      </section>

      <ClientReviews></ClientReviews>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Social Media Marketing" />
)